<template>
  <div class="maintenance">
    <div class="info-text">
      <h2 class="title text-center">
        The system is currently under maintenance
      </h2>
      <p class="desc text-center">
        This is to serve you better in the future. We apologize for any
        inconvenience caused, please check back the site later.
      </p>
      <p class="mt-2 desc tired text-center">
        Anyway, here is a tired developer.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    if (process.env.VUE_APP_MAINTENANCE_MODE == 0) {
      this.$router.replace({ name: "Entry" });
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.maintenance {
  min-height: 100vh;
  background: url("~@/assets/image/illustration/maintenance.svg");
  background-repeat: no-repeat;
  background-size: 30vh;
  background-position: right 50% bottom 10%;
  background-color: lighten($color-main, 40);
  .info-text {
    max-width: 600px;
    margin: auto;
    padding-left: 25px;
    padding-right: 25px;

    .title {
      padding-top: 10vh;
      padding-bottom: 30px;
      font-size: 32px;
      color: #303030;
    }
    .desc {
      font-size: 16px;
      color: #505050;
    }
    .tired {
      font-size: 12px;
    }
  }
}
</style>
